







import Vue from 'vue';
import Tablet from '@/components/Tablet.vue'

export default Vue.extend({
  name: 'GameVideo',
  components: {
    Tablet
  },
  props: {
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: true
    },
    vidId: {
      type: String,
      default: 'kUqVKph8uAg'
    },
    vidConfig: {
      type: Object,
      default () {
        return {
          autoplay:       '1',
          cc_load_policy: '1',
          controls:       '1',
          enablejsapi:    '1',
          modestbranding: '1',
          origin: location.origin,
          rel:            '0'
        }
      },
    },
    showButtonsAfter: {
      type: Number,
      default: 60
    },
    closeButtonText: {
      type: String,
      default: "Close Video"
    }
  },
  data () {
    const vidParams = new URLSearchParams(this.vidConfig)
    return {
      active: false,
      vidSrc: 'https://www.youtube.com/embed/'+this.vidId+'?'+vidParams.toString(),
      vidSize: {
        height: 480,
        width: 640
      },
      hideButtons: true,
    }
  },
  computed: {
  },
  methods: {
    show () {
      console.log('GameVideo.show')
      this.active = true
      this.$refs.tablet.show()
    },
    hide () {
      this.$refs.tablet.hide()
      this.active = false
    },
    updateIframeSize (newSize) {
      this.vidSize = newSize
    },
    close () {
      this.$emit('close')
      this.hide()
    },
    // handleAlienMessages (event) {
    //   console.log('Alien message received.', event)
    // }
  },
  mounted () {
    console.log('Video.mounted', this.vidSrc)
    this.active = this.startActive
    window.setTimeout(()=>{
      this.hideButtons = false
    }, this.showButtonsAfter*1000)
    // window.addEventListener("message", this.handleAlienMessages)
    // this.$refs.vid.addEventListener("message", this.handleAlienMessages)
  },
  beforeDestroy () {
    // window.removeEventListener("message", this.handleAlienMessages)
  }
});
